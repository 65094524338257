// Libraries
import Link from "next/link";
import EpisodeInline from "../../Episode/EpisodeInline/EpisodeInline";
import Button from "../../../ui/Button/Button";
import Image from "next/image";
import { useEffect, useState } from "react";

export default function SerieModale({ serie, ...props }) {
    // States
    const [selectedSeason, setSelectedSeason] = useState(1);
    const [episodes, setEpisodes] = useState([]);
    const [numberOfSeasonsOnline, setNumberOfSeasonsOnline] = useState(0);

    // Methods
    const onSeasonCliked = (e) => {
        setSelectedSeason(parseInt(e.target.value));
        refreshEpisodes();
    };
    const refreshEpisodes = () => {
        if (serie && serie.seasons && serie.seasons[selectedSeason - 1]) {
            // Fetch the list of episodes for the selected season
            let temp = [];
            serie.seasons[parseInt(selectedSeason) - 1].episodes.forEach(
                (episode) => {
                    if (episode.isOnline) temp.push(episode);
                },
            );
            setEpisodes(temp);
        }
    };

    useEffect(() => {
        let number = 0;
        serie.seasons.forEach((season) => {
            if (season.isOnline) number++;
        });
        setNumberOfSeasonsOnline(number);
        refreshEpisodes();
    }, []);

    useEffect(() => {
        // Call refreshEpisodes when the selected season changes
        refreshEpisodes();
    }, [selectedSeason]);

    return (
        <div
            className="bg-bgDark-840 rounded-2xl overflow-hidden relative mx-auto z-50 cursor-auto"
            onClick={(e) => e.stopPropagation()}
            style={{
                marginTop: 130,
                marginBottom: 50,
                maxWidth: 920,
                width: "100%",
                boxShadow: "rgba(0, 0, 0, 0.75) 0px 3px 10px",
            }}
        >
            <div className="relative flex flex-col">
                <div className="aspect-w-16 aspect-h-9 relative w-full h-0">
                    {/* Image */}
                    <Image
                        src={serie.image}
                        alt={serie.title}
                        layout="fill"
                        className="object-cover"
                    />
                    <div
                        className="absolute top-0 left-0 right-0 bottom-0 flex flex-col"
                        style={{
                            background:
                                "linear-gradient(0deg,#2A2B3E,transparent 50%)",
                        }}
                    >
                        <div className="flex justify-end md:p-10 p-5 flex-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-10 w-10 text-white hover:text-primary duration-150 cursor-pointer"
                                onClick={(e) => {
                                    props.setModalIsOpen(false);
                                }}
                                viewBox="0 0 24 24"
                            >
                                <g fill="currentColor">
                                    <path
                                        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2s10 4.477 10 10Z"
                                        opacity=".5"
                                    ></path>
                                    <path d="M8.97 8.97a.75.75 0 0 1 1.06 0L12 10.94l1.97-1.97a.75.75 0 1 1 1.06 1.06L13.06 12l1.97 1.97a.75.75 0 0 1-1.06 1.06L12 13.06l-1.97 1.97a.75.75 0 0 1-1.06-1.06L10.94 12l-1.97-1.97a.75.75 0 0 1 0-1.06Z"></path>
                                </g>
                            </svg>
                        </div>
                        <div className="md:p-10 p-5">
                            <div
                                className="flex flex-col justify-end items-center h-full relative"
                                style={{ width: "40%" }}
                            >
                                {/* Title */}
                                <Image
                                    src={serie.imageTitle}
                                    alt="Title"
                                    width={625}
                                    height={250}
                                    className="object-contain"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:px-10 px-5 mb-10 text-white">
                <div className="text-lg">
                    {serie.isBelievemyOriginals && "Believemy Originals・"}
                    {numberOfSeasonsOnline}{" "}
                    {numberOfSeasonsOnline > 1 ? "saisons" : "saison"}
                    ・Série
                </div>
                <div className="mt-4">
                    {/* If serie has one season or more, show the first episode of the season 1 */}
                    {serie.seasons.length > 0 &&
                        serie.seasons[0].isOnline &&
                        serie.seasons[0].episodes &&
                        serie.seasons[0].episodes[0] &&
                        serie.seasons[0].episodes[0].isOnline && (
                            <Link href={`/series/${serie.slug}/1/1`}>
                                <Button type="white">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 mr-3 text-excitationOrange"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M21.409 9.353a2.998 2.998 0 0 1 0 5.294L8.597 21.614C6.534 22.736 4 21.276 4 18.968V5.033c0-2.31 2.534-3.769 4.597-2.648l12.812 6.968Z"
                                        ></path>
                                    </svg>
                                    Regarder
                                </Button>
                            </Link>
                        )}
                </div>
                <div className="mt-10">
                    <div
                        className="text-xl bold"
                        style={{ marginBottom: "0.5em" }}
                    >
                        Présentation
                    </div>
                    <div>{serie.description}</div>
                </div>
                {/* Episodes */}
                {serie.seasons.length != 0 && (
                    <div className="mt-10">
                        <div
                            className="text-xl bold flex items-center justify-between"
                            style={{ marginBottom: "0.5em" }}
                        >
                            Épisodes
                            <div className="text-sm">
                                {/* Can select one season */}
                                <select
                                    className="bg-bgDark-700 text-white rounded px-3 py-1"
                                    style={{ outline: "none" }}
                                    onChange={(e) => {
                                        onSeasonCliked(e);
                                    }}
                                >
                                    {serie.seasons.map((season, index) => {
                                        if (!season.isOnline) return;
                                        return (
                                            <option
                                                value={season.position}
                                                key={`serie-season-${index}`}
                                            >
                                                Saison {season.position}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="rounded overflow-hidden divide-y divide-borderDark">
                            {episodes.map((episode, index) => (
                                <Link
                                    href={`/series/${serie.slug}/${selectedSeason}/${episode.position}`}
                                    className="text-white hover:text-white"
                                    key={`serie-episode-${index}`}
                                >
                                    <EpisodeInline episode={episode} />
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
