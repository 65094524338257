"use client";

// Library
import Link from "next/link";

// Components
import { motion } from "framer-motion";
import Button from "../../Button/Button";
import Image from "next/image";

export default function EndCTA(props) {
    if (!props.join && !props.mentorat && !props.apprenticeship) {
        return (
            <>
                <section className="mt-52 container dark:text-white text-center">
                    <motion.h2
                        className="font-bold md:text-7xl sm:text-6xl text-5xl xl:w-8/12 w-11/12 mx-auto"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {/* L’endroit pour tout ceux qui croient en eux, partout
                        dans le monde. */}
                        Essayez gratuitement
                    </motion.h2>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        Que vous essayiez de scaler votre start-up, de créer
                        votre premier site internet ou de vous reconvertir en
                        tant que développeur, Believemy est votre nouvelle
                        maison. Rejoignez-nous, évoluez et construisons ensemble
                        votre projet.
                    </motion.p>
                    <motion.div
                        className="flex justify-center mt-10"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        <Link href="/signup">
                            <Button type="pop">
                                Essayer Believemy gratuitement
                            </Button>
                        </Link>
                    </motion.div>
                    <div className="mt-32 flex justify-center">
                        <Image
                            src="/images/site/assets/glob.svg"
                            alt="Believemy is with anyone"
                            width={1167}
                            height={500}
                            className="object-contain"
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                        />
                    </div>
                </section>
            </>
        );
    }
    if (props.join) {
        return (
            <>
                <section className="mt-52 container dark:text-white text-center">
                    <motion.h2
                        className="font-bold md:text-6xl text-5xl xl:w-8/12 w-11/12 mx-auto"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        Rejoignez le mouvement.
                    </motion.h2>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        Envie de nous rejoindre ? C'est simple, il vous suffit
                        de nous contacter par email en cliquant sur le bouton
                        ci-dessous, et de nous en dire le plus possible sur le
                        cours que vous souhaiteriez créer.
                    </motion.p>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        Nous n'acceptons que les cours de qualité, et nous nous
                        réservons le droit de refuser un cours si nous le
                        jugeons inadapté. Un formateur Believemy est un réel
                        partenaire de confiance sur le long terme, chaque
                        proposition est donc étudiée avec soin.
                    </motion.p>
                    <motion.div
                        className="flex justify-center mt-10"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        <Link href="mailto:hello@believemy.com">
                            <Button>Proposer un cours</Button>
                        </Link>
                    </motion.div>
                    <div className="mt-32 flex justify-center">
                        <Image
                            src="/images/site/assets/glob.svg"
                            alt="Believemy is with anyone"
                            width={1167}
                            height={500}
                            className="object-contain"
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                        />
                    </div>
                </section>
            </>
        );
    }
    if (props.mentorat) {
        return (
            <>
                <section className="mt-52 container dark:text-white text-center">
                    <motion.h2
                        className="font-bold md:text-6xl text-5xl xl:w-8/12 w-11/12 mx-auto"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        Rejoignez le mouvement.
                    </motion.h2>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        Envie de nous rejoindre ? C'est simple, il vous suffit
                        de nous contacter par email en cliquant sur le bouton
                        ci-dessous, et de nous en dire le plus possible sur
                        vous.
                    </motion.p>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        Nous n'acceptons que les mentors qui peuvent réellement
                        apporter une différence pour nos étudiants. Ceci par son
                        parcours, sa façon de penser, d'être ou même par ses
                        capacités. Un mentor Believemy est un réel partenaire de
                        confiance sur le long terme, chaque proposition est donc
                        étudiée avec soin.
                    </motion.p>
                    <motion.div
                        className="flex justify-center mt-10"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        <Link href="mailto:hello@believemy.com">
                            <Button>Devenir mentor</Button>
                        </Link>
                    </motion.div>
                    <div className="mt-32 flex justify-center">
                        <Image
                            src="/images/site/assets/glob.svg"
                            alt="Believemy is with anyone"
                            width={1167}
                            height={500}
                            className="object-contain"
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                        />
                    </div>
                </section>
            </>
        );
    }
    if (props.apprenticeship) {
        return (
            <>
                <section className="mt-32 container dark:text-white text-center">
                    <motion.h2
                        className="font-bold md:text-6xl text-5xl xl:w-8/12 w-11/12 mx-auto"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        Rejoignez l'alternance par Believemy
                    </motion.h2>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        On est prêt ? C'est très simple : il vous suffit de
                        cliquer sur le bouton ci-dessous et de suivre les étapes
                        sur la page suivante.
                    </motion.p>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        Notre équipe admission est disponible à tout moment pour
                        vous aider et pour répondre à vos questions. N'hésitez
                        pas !
                    </motion.p>
                    <motion.div
                        className="flex justify-center gap-5 mt-10"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        <Link href="/program/le-programme-rocket/admission">
                            <Button type="confidence">
                                L'alternance, c'est parti !
                            </Button>
                        </Link>
                        <Link href="mailto:hello@believemy.com">
                            <Button type="light">Être recontacté</Button>
                        </Link>
                    </motion.div>
                    <div className="mt-32 flex justify-center">
                        <Image
                            src="/images/site/assets/glob.svg"
                            alt="Believemy is with anyone"
                            width={1167}
                            height={500}
                            className="object-contain"
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                        />
                    </div>
                </section>
            </>
        );
    }
}
