// Libraries
import LinesEllipsis from "react-lines-ellipsis";
import { useState } from "react";

export default function Ellipsis(props) {
    // States
    const [showAll, setShowAll] = useState(false);
    const [ellipsis, setEllipsis] = useState(false);

    return (
        <>
            <div className={props.className}>
                <LinesEllipsis
                    text={props.children}
                    maxLine={showAll ? 10000 : props.maxLines}
                    onReflow={(e) => setEllipsis(e.clamped)}
                />
            </div>

            {/* If lines are greater 5 */}
            {ellipsis && !showAll && !props.disableReadMore && (
                <div
                    onClick={() => setShowAll(true)}
                    className="cursor-pointer text-primary inline-flex gap-1 items-center mt-1 hover:text-primary-900"
                >
                    Voir plus
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                    </svg>
                </div>
            )}
        </>
    );
}
