// Components
import Image from "next/image";
import Icon from "../../ui/Icon/Icon";

export default function Highlight({
    data: highlight,
    isHighlighted,
    ...props
}) {
    if (highlight.type == "episode") {
        return (
            <div className="relative">
                <div
                    className="relative hover:scale-[0.98]"
                    onClick={() => props.onClick(highlight)}
                >
                    <div
                        className={`rounded-card overflow-hidden relative cursor-pointer border duration-150 ${
                            isHighlighted
                                ? "border-primary"
                                : "border-transparent"
                        } hover:border-primary`}
                    >
                        {/* Believemy Origignals ? */}
                        {highlight.episode.isBelievemyOriginals && (
                            <div className="absolute left-3 top-3 z-10">
                                <Icon white ratio="0.015" />
                            </div>
                        )}
                        {highlight.episode.thumbnail ? (
                            <Image
                                src={highlight.episode.thumbnail}
                                alt={highlight.episode.title}
                                width={1920 * 0.3}
                                height={1080 * 0.3}
                                className="rounded"
                            />
                        ) : (
                            <div className="aspect-w-16 aspect-h-9 bg-gold rounded"></div>
                        )}
                    </div>
                </div>
                {!props.withoutStatus && (
                    <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                        <div className="px-3 py-1 text-white bg-red-500 text-xs rounded bold">
                            Épisode
                        </div>
                    </div>
                )}
            </div>
        );
    }

    if (highlight.type == "course") {
        return (
            <div className="relative">
                <div
                    className="relative hover:scale-[0.98]"
                    onClick={() => props.onClick(highlight)}
                >
                    <div
                        className={`rounded-card overflow-hidden relative cursor-pointer border duration-150 ${
                            isHighlighted
                                ? "border-primary"
                                : "border-transparent"
                        } hover:border-primary`}
                    >
                        {/* Believemy Origignals ? */}
                        {highlight.course.isBelievemyOriginals && (
                            <div className="absolute left-3 top-3 z-10">
                                <Icon white ratio="0.015" />
                            </div>
                        )}
                        {highlight.course.socialImage ? (
                            <Image
                                src={highlight.course.socialImage}
                                alt={highlight.course.name}
                                width={1920 * 0.3}
                                height={1080 * 0.3}
                                className="rounded"
                            />
                        ) : (
                            <div className="aspect-w-16 aspect-h-9 bg-primary rounded"></div>
                        )}
                    </div>
                </div>
                {!props.withoutStatus && (
                    <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                        <div className="px-3 py-1 text-white bg-red-500 text-xs rounded bold">
                            Formation
                        </div>
                    </div>
                )}
            </div>
        );
    }

    if (highlight.type == "announcement") {
        return (
            <>
                <div
                    className="relative hover:scale-[0.98]"
                    onClick={() => props.onClick(highlight)}
                >
                    <div
                        className={`rounded-card overflow-hidden relative cursor-pointer border duration-150 ${
                            isHighlighted
                                ? "border-primary"
                                : "border-transparent"
                        } hover:border-primary`}
                    >
                        {/* Believemy Origignals ? */}
                        {highlight.announcement.isBelievemyOriginals && (
                            <div className="absolute left-3 top-3 z-10">
                                <Icon white ratio="0.015" />
                            </div>
                        )}
                        {highlight.announcement.thumbnail ? (
                            <Image
                                src={highlight.announcement.thumbnail}
                                alt={highlight.announcement.title}
                                width={1920 * 0.3}
                                height={1080 * 0.3}
                                className="rounded"
                            />
                        ) : (
                            <div className="aspect-w-16 aspect-h-9 bg-primary rounded"></div>
                        )}
                    </div>
                </div>
                {!props.withoutStatus && (
                    <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                        <div className="px-3 py-1 text-white bg-red-500 text-xs rounded bold">
                            Annonce
                        </div>
                    </div>
                )}
            </>
        );
    }
}
