// Libraries
import Link from "next/link";
import { capitalize } from "../../../helpers/capitalize";
import moment from "moment-timezone";
import "moment/locale/fr";

export default function BookedEvent({ event, session, ...props }) {
    return (
        <Link
            href={
                event.event
                    ? `/events/${event.event.slug}/private-${event.eventDate.id}`
                    : `/call/${event.token}`
            }
        >
            <div className="bg-gray-100 dark:bg-bgDark-840 rounded-card hover:scale-[0.98] p-5 border dark:border-borderDark hover:border-primary dark:hover:border-primary duration-150">
                {/* Type */}
                <div className="text-lg bold text-black dark:text-white mb-1">
                    {event.event ? "Événement en direct" : "Appel de mentorat"}
                </div>

                {/* Content */}
                <div className="text-black dark:text-white mb-4 h-14">
                    {event.event
                        ? event.event.title
                        : `Vous avez un appel avec ${
                              event.mentor.id == session.user.userId
                                  ? event.mentee.pseudo &&
                                    event.mentee.showPseudo
                                      ? capitalize(event.mentee.pseudo)
                                      : `${capitalize(
                                            event.mentee.firstName,
                                        )} ${capitalize(event.mentee.lastName)}`
                                  : `${capitalize(
                                        event.mentor.firstName,
                                    )} ${capitalize(event.mentor.lastName)}`
                          }.`}
                </div>

                {/* Date */}
                <div className="flex items-center gap-1 text-primary">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M7 1.75a.75.75 0 0 1 .75.75v.763c.662-.013 1.391-.013 2.194-.013h4.112c.803 0 1.532 0 2.194.013V2.5a.75.75 0 0 1 1.5 0v.827c.26.02.506.045.739.076c1.172.158 2.121.49 2.87 1.238c.748.749 1.08 1.698 1.238 2.87c.05.375.084.784.107 1.23a.747.747 0 0 1 .019.46c.027.801.027 1.712.027 2.743v2.112c0 1.838 0 3.294-.153 4.433c-.158 1.172-.49 2.121-1.238 2.87c-.749.748-1.698 1.08-2.87 1.238c-1.14.153-2.595.153-4.433.153H9.944c-1.838 0-3.294 0-4.433-.153c-1.172-.158-2.121-.49-2.87-1.238c-.748-.749-1.08-1.698-1.238-2.87c-.153-1.14-.153-2.595-.153-4.433v-2.112c0-1.031 0-1.942.027-2.744a.75.75 0 0 1 .02-.46c.022-.445.056-.854.106-1.229c.158-1.172.49-2.121 1.238-2.87c.749-.748 1.698-1.08 2.87-1.238c.233-.031.48-.056.739-.076V2.5A.75.75 0 0 1 7 1.75Zm-4.237 8c-.013.653-.013 1.396-.013 2.25v2c0 1.907.002 3.262.14 4.29c.135 1.005.389 1.585.812 2.008c.423.423 1.003.677 2.009.812c1.028.138 2.382.14 4.289.14h4c1.907 0 3.262-.002 4.29-.14c1.005-.135 1.585-.389 2.008-.812c.423-.423.677-1.003.812-2.009c.138-1.027.14-2.382.14-4.289v-2c0-.854 0-1.597-.013-2.25H2.763Zm18.405-1.5H2.832c.016-.19.035-.369.058-.54c.135-1.005.389-1.585.812-2.008c.423-.423 1.003-.677 2.009-.812c1.028-.138 2.382-.14 4.289-.14h4c1.907 0 3.262.002 4.29.14c1.005.135 1.585.389 2.008.812c.423.423.677 1.003.812 2.009c.023.17.042.35.058.539Zm-10.381 4.057a.75.75 0 0 1 .463.693v4a.75.75 0 0 1-1.5 0v-2.19l-.22.22a.75.75 0 0 1-1.06-1.06l1.5-1.5a.75.75 0 0 1 .817-.163ZM14 13.75a.25.25 0 0 0-.25.25v2a.25.25 0 1 0 .5 0v-2a.25.25 0 0 0-.25-.25Zm-1.75.25a1.75 1.75 0 1 1 3.5 0v2a1.75 1.75 0 1 1-3.5 0v-2Z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    <div className="text-gray-500 dark:text-lightBlue">
                        {capitalize(
                            moment
                                .utc(
                                    event.event
                                        ? event.eventDate.date
                                        : event.plannedDate,
                                )
                                .tz(session.user.timezone)
                                .calendar(null, {
                                    sameDay: "[Aujourd'hui à] HH:mm",
                                    nextDay: "[Demain à] HH:mm",
                                    nextWeek: "dddd [prochain à] HH:mm",
                                    lastDay: "[Hier]",
                                    lastWeek: "dddd",
                                    sameElse: "ddd DD MMMM [à] HH:mm",
                                }),
                        )}
                    </div>
                </div>
                {/* Time */}
                <div className="flex items-center gap-1 text-primary">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M12 2.75a9.25 9.25 0 1 0 0 18.5a9.25 9.25 0 0 0 0-18.5ZM1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12S17.937 22.75 12 22.75S1.25 17.937 1.25 12ZM12 7.25a.75.75 0 0 1 .75.75v3.69l2.28 2.28a.75.75 0 1 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1-.22-.53V8a.75.75 0 0 1 .75-.75Z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    <div className="text-gray-500 dark:text-lightBlue">
                        {event.event
                            ? event.event.duration
                            : `${event.minutesPerCall} minutes`}
                    </div>
                </div>

                {/* Link */}
                <div className="flex justify-end">
                    <div className="inline-flex items-center gap-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M8.512 4.43a.75.75 0 0 1 1.057.082l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.138-.976L14.012 12L8.431 5.488a.75.75 0 0 1 .08-1.057Z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        Accéder à cet appel
                    </div>
                </div>
            </div>
        </Link>
    );
}
