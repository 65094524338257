// Libraries
import Link from "next/link";
import moment from "moment";
import "moment/locale/fr";
import Image from "next/image";
import Ellipsis from "../ui/Ellipsis/Ellipsis";

export default function ArticleCard(props) {
    // Session
    const sess = props.session ? props.session.user.timezone : "Europe/Paris";

    // Variables
    const { article } = props;
    let dateCreation;
    let link;

    if (props.admin) {
        link = `/admin/article/${article.slug}`;
        dateCreation = moment(article.submissionDate).calendar();
    } else if (props.writer) {
        link = `/writer/article/${article.slug}`;
    } else {
        link = `/r/${article.slug}`;
        dateCreation = moment(article.dateCreation).calendar();
    }

    return (
        <Link href={link}>
            <div
                key={`article-${props.index || props.key}`}
                className="bg-gray-100 dark:bg-bgDark-840 rounded-card hover:scale-[0.98] overflow-hidden h-full text-black dark:text-white border dark:border-borderDark hover:border-primary dark:hover:border-primary duration-150"
            >
                <div className="flex flex-col justify-between h-full">
                    <div className="flex-grow flex flex-col">
                        {/* Cover */}
                        <div className="aspect-w-16 aspect-h-9 h-44">
                            <Image
                                src={article.cover}
                                alt={article.title}
                                layout="fill"
                                className="object-cover"
                            />
                        </div>
                        <div className="p-5 flex flex-col flex-grow">
                            {/* Title */}
                            <div className="flex-grow font-semibold">
                                <Ellipsis maxLines="3" disableReadMore>
                                    {article.title}
                                </Ellipsis>
                            </div>

                            {/* Date */}
                            <div className="mt-4">
                                {props.admin ? (
                                    <small className="flex items-center gap-1 text-textSecondDark group-hover:text-gray-200">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-3 w-3"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                            />
                                        </svg>
                                        {dateCreation}
                                    </small>
                                ) : (
                                    <div className="text-sm text-gray-500 dark:text-white">
                                        {moment
                                            .utc(article.dateCreation)
                                            .tz(sess)
                                            .fromNow()}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Description */}
                    {/* <p className="text-md mt-3 text-justify mx-8 mb-8 text-gray-700 dark:text-textDark group-hover:text-gray-100">
                        <Ellipsis maxLines="4" disableReadMore>
                            {article.description}
                        </Ellipsis>
                    </p> */}
                </div>
            </div>
        </Link>
    );
}
