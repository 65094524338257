import Link from "next/link";

export default function Mentor({ mentor, key, fromSlider }) {
    return (
        <Link
            href={`${
                mentor.mentorPseudo
                    ? `${process.env.NEXTAUTH_URL}/mentors/${mentor.mentorPseudo}`
                    : `${process.env.NEXTAUTH_URL}/mentors/believer-${mentor.id}`
            }`}
            key={key}
        >
            <div
                className={`rounded-card dark:text-white aspect-[3/4] border dark:border-borderDark relative overflow-hidden shadow hover:shadow-lg ${
                    fromSlider ? "hover:scale-[0.98]" : "hover:scale-[1.03]"
                } hover:border-primary duration-150`}
                style={{
                    backgroundImage: `url(${mentor.mentorPicture})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top center",
                }}
            >
                <div
                    className="absolute bottom-0 left-0 right-0 h-48"
                    style={{
                        background:
                            "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)",
                    }}
                ></div>
                <div className="absolute bottom-0 left-0 right-0 p-5 text-center">
                    <div className="text-xl font-bold text-white">
                        {mentor.firstName}
                        <div className="text-3xl uppercase">
                            {mentor.lastName}
                        </div>
                    </div>
                    <div className="w-1/3 h-[3px] rounded-full bg-white bg-opacity-30 mx-auto mt-3"></div>
                    <div className="mt-3 italic h-12 text-white">
                        {mentor.mentorBio}
                    </div>
                </div>
            </div>
        </Link>
    );
}
